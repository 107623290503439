a:link{
    color: #333;
}
a:visited {
    color: #333;
    text-decoration: none;
}
a:hover{
    color: #333;
    text-decoration: none !important;
}
a:active{
    color: #333;
    text-decoration: none;
}
.radio-inline-lang{
    padding-left: 30px !important;
}

.radio-inline {
    padding-left: 70px !important;
}

.p2-login-bg{
    background-image: linear-gradient( rgb(217 227 237), rgb(214 224 234), rgb(219 229 238));
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
}