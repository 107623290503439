label {
    font-weight: 400 !important;
}

h4 {
    color: #96b414 !important;
    font-weight: 400 !important;
    font-size: 30px !important;
}

p {
    line-height: 50px;
    margin: 0 !important;
    font-weight: 400 !important;
    font-size: 24px !important;
}

/* div {
    font-weight: 400;
    font-size: 24px ;
} */

.form-control-home {
    border: 1px solid #040000 !important;
    background-color: #fff0 !important;
    height: 64px !important;
    font-size: 24px !important;
    padding: 6px 18px!important;
    border-radius: 6px !important;
}

input[type=checkbox], input[type=radio] {
    margin-top: 4px !important;
    background-color: #fff0 !important;
}

.btn-submit {
    background-color: #96b414 !important;
    border: 0 !important;
}

.err {
    color: rgb(213, 69, 69) !important;
}

.form-group {
    margin-bottom: 25px !important;
    position: relative;
}

.chooseData {
    line-height: 64px !important;
    display: flex;
    margin-top: 5px;
}

input[type="radio"] {
    margin-top: 7px !important;
    /* -webkit-appearance:none; */
}

.input-error {
    border: 1px solid rgb(213, 69, 69) !important;
}


.radio-inline input[type=radio] {
    width: 22px;
    height: 22px;
    /* appearance: none; */
}

input[type="radio"]:after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    background:#96b414;
    border-radius: 50%;

    width: 24px;
    height: 24px;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: all .2s ease;
    transform-origin: center center;
    pointer-events: none;

}

input[type="radio"]:checked:after {
    width: 24px;
    height: 24px;
    opacity: 1;
}

input[type=radio]:before {
    background-color: #e1eaf2;
    position: absolute;
    width: 22px;
    height: 22px;
    content: " ";
    border-radius: 50%;
    border: 1px solid black !important;
    display: inline-block;
    visibility: visible;
}




input[type="checkbox"]:before {
    background-color: #e1eaf2;
    position: absolute;
    width: 22px;
    height: 22px;
    content: " "!important;
    border-radius: 50%!important;
    border: 1px solid #040000;
    
}



input[type="checkbox"] {
    border-radius: 50%;
    appearance: none;
    outline:none;
}

input[type="checkbox"]:after {
    border-radius: 50%;
    content: '';
    display: inline-block;
    position: absolute;
    left: -1px;
    top: -1px;
    font-size: 2.5em;
    font-family: meiryo, "sans-serif";
    opacity: 0;
    transition: all .1s ease;
    transform-origin: center center;
    width: 24px;
    height: 24px;
    background: #96b414;
    border-radius: 50%;
    appearance: none;

}
input:focus {
    outline:none !important;
 }
input[type="checkbox"]:checked:after {
    opacity: 1;
    appearance: none;
    
}

.checkbox-inline, .radio-inline {
    padding-left: 70px !important;
}

.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
    position: absolute;
    margin-left: -31px !important;
}

/* .checkbox-inline+.checkbox-inline, .radio-inline+.radio-inline {
    margin-top: 0;
    margin-left: 10px !important;
} */

.baoming{
    font-size: 25px !important;
}

.xiaozi{
    font-size: 16px !important;
    margin: 0 !important;
    line-height: 12px !important;
    margin-bottom: 8px !important;
    color: #96b414;
}
.xiaozi2{
    font-size: 14px !important;
    margin: 0 !important;
    line-height: 10px !important;
    margin-bottom: 14px !important;
    color: #96b414;
}
.container{
    
}
.btn{
    border-radius: 10px !important;
}
select{
    appearance:none;
    -moz-appearance:none;
    -webkit-appearance:none;
    -ms-appearance:none;
}

.jiantou{
    position: absolute;
    width: 18px;
    right: 15px;
    bottom: 40%;
    pointer-events: none
}
.jiantou2{
    position: absolute;
    width: 3%;
    right: 3%;
    bottom: 40%;
    pointer-events: none
}
.jiantou_kuang{
    position: relative;
}
.label_eng{
    font-size: 16px!important;
    line-height: 16px;
    margin-bottom: 14px;
    margin-top: 5px;
}
.label_eng_title{
    font-size: 18px!important;
}
.label_eng_text{
    font-size: 15px!important;
}
.title{
    font-size: 22px;
}
.title1{
    margin-bottom: 15px;
}
.title2{
    color: #96b414;;
    font-size: 28px;
    border-bottom: solid 1.5px #96b414;
    width: 112px;
    font-weight: 500;
    padding-bottom: 2px;
}
.title2-kuang{
    margin: 20px 0;
}
.label_eng_title_no{
    margin-left: 180px !important;
}
.label_eng_text_test{
    margin-left: 40px !important;
}
.label_eng_title_pass{
    margin-left: 110px !important;
}
.radio-inline-lang{
    padding-left: 30px !important;
}
.checkbox-inline2, .radio-inline2 {
    padding-left: 32px !important;
}
.input-groupbtn {
    width: 170px!important;
    line-height: 46px!important;
    border-bottom-left-radius: 0!important;
    border-top-left-radius: 0 !important;
    color: #fff!important;
    background-color: #999!important;
    border-color: #333!important;
    border-left: none!important;
    font-size: 22px!important;
}

.baseline-input{
    vertical-align: baseline !important;
}
.baseline-input2{
    vertical-align: baseline !important;
    padding-left: 60px!important;
}

.xiao1{
    font-size: 18px!important;
    margin-top: 10px;
    font-weight: 300;
}
.xiao2{
    font-size: 20px!important;
    margin-top: 5px;
    font-weight: 300;
}
.lianjie{
    position: absolute;
    width: 90%;
    height: 60px;
    bottom: 20px;
    background-color: #333;
    opacity: 0;
}
.baseline-input-en1{
    vertical-align: baseline !important;
    padding-left: 40px !important;
}

.baseline-input-en2{
    vertical-align: baseline !important;
    padding-left: 160px !important;
}

.room-radio1{
    padding-left: 120px !important;
}

.room-radio2{
    padding-left: 120px !important;
}