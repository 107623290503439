label {
    font-weight: 400 !important;
}

h4 {
    color: #96b414 !important;
    font-weight: 400 !important;
    font-size: 30px !important;
}

p {
    line-height: 50px;
    margin: 0 !important;
    font-weight: 400 !important;
    font-size: 20px !important;
}



.form-control-login {
    border: 1px solid #040000 !important;
    background-color: #fff0 !important;
    height: 70px !important;
    font-size: 28px !important;
    padding: 6px 18px!important;
    border-radius: 10px !important;
}

input[type=checkbox], input[type=radio] {
    margin-top: 4px !important;
    background-color: #fff0 !important;
}

.btn-submit {
    background-color: #96b414 !important;
    border: 0 !important;
}

.err {
    color: rgb(213, 69, 69) !important;
}

.jiantou-login-2 {
    position: absolute;
    width: 8%;
    right: 4%;
    top: 18px;
    pointer-events: none;
}
.titlelogin {
    margin-bottom: 15px;
    text-transform: uppercase;
}

.form-group-login{
    margin-bottom: 25px !important;
    position: relative;
    font-size: 28px!important;
}

.chooseData {
    line-height: 60px !important;
    display: flex;
    margin-top: 5px;
}

input[type="radio"] {
    margin-top: 4px !important;
    /* -webkit-appearance:none; */
}

.input-error {
    border: 1px solid rgb(213, 69, 69) !important;
}


.radio-inline input[type=radio] {
    width: 22px;
    height: 22px;
    /* appearance: none; */
}

input[type="radio"]:after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    background:#96b414;
    border-radius: 50%;

    width: 24px;
    height: 24px;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: all .2s ease;
    transform-origin: center center;
    pointer-events: none;

}

input[type="radio"]:checked:after {
    width: 24px;
    height: 24px;
    opacity: 1;
}

input[type=radio]:before {
    background-color: #e1eaf2;
    position: absolute;
    width: 22px;
    height: 22px;
    content: " ";
    border-radius: 50%;
    border: 1px solid black !important;
    display: inline-block;
    visibility: visible;
}




input[type="checkbox"]:before {
    background-color: #e1eaf2;
    position: absolute;
    width: 22px;
    height: 22px;
    content: " "!important;
    border-radius: 50%!important;
    border: 1px solid #040000;
    
}



input[type="checkbox"] {
    border-radius: 50%;
    appearance: none;
    outline:none;
}

input[type="checkbox"]:after {
    border-radius: 50%;
    content: '';
    display: inline-block;
    position: absolute;
    left: -1px;
    top: -1px;
    font-size: 2.5em;
    font-family: meiryo, "sans-serif";
    opacity: 0;
    transition: all .1s ease;
    transform-origin: center center;
    width: 24px;
    height: 24px;
    background: #96b414;
    border-radius: 50%;
    appearance: none;

}
input:focus {
    outline:none !important;
 }
input[type="checkbox"]:checked:after {
    opacity: 1;
    appearance: none;
    
}

.checkbox-inline, .radio-inline {
    padding-left: 70px !important;
}

.checkbox-inline2, .radio-inline2 {
    padding-left: 32px !important;
}

.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
    position: absolute;
    margin-left: -31px !important;
}

/* .checkbox-inline+.checkbox-inline, .radio-inline+.radio-inline {
    margin-top: 0;
    margin-left: 10px !important;
} */

.baoming{
    font-size: 25px !important;
}

.xiaozi{
    font-size: 16px !important;
    margin: 0 !important;
    line-height: 12px !important;
    margin-bottom: 8px !important;
    color: #96b414;
}
.xiaozi2{
    font-size: 14px !important;
    margin: 0 !important;
    line-height: 10px !important;
    margin-bottom: 14px !important;
    color: #96b414;
}
.container{
    width: 100% !important;
}
.btn{
    border-radius: 10px !important;
}
select{
    appearance:none;
    -moz-appearance:none;
    -webkit-appearance:none;
    -ms-appearance:none;
}

.jiantou-login{
    position: absolute;
    width: 5%;
    right: 5%;
    top: 25px;
    pointer-events: none
}
.jiantou_kuang{
    position: relative;
}
.label_eng{
    font-size: 16px!important;
    line-height: 16px;
    margin-bottom: 14px;
    margin-top: 5px;
}
.label_eng_title{
    font-size: 18px!important;
}
.label_eng_text{
    font-size: 15px!important;
}
.title{
    font-size: 22px;
}
.label_eng_title_no{
    margin-left: 180px !important;
}
.label_eng_text_test{
    margin-left: 74px !important;
}
.label_eng_title_pass{
    margin-left: 110px !important;
}
.input-groupbtn{
    width: 150px!important;
    line-height: 46px!important;
    border-bottom-left-radius: 0!important;
    border-top-left-radius: 0 !important;
    color: #fff!important;
    background-color: #999!important;
    border-color: #333!important;
    border-left: none!important;
    font-size: 22px!important;
}
.input-group-yan{
    width: 280px;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.p2-login-bg{
    background-color: #bcd1df;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
}
